<template>
    <div class="playground">
        <GameMap />
        <div class="user-color A" v-if="$store.state.pk.a_id === parseInt($store.state.user.id)">你的角色是: A</div>
        <div class="user-color B" v-if="$store.state.pk.b_id === parseInt($store.state.user.id)">你的角色是: B</div>
    </div>
</template>

<script>
import GameMap from './GameMap.vue'

export default {
    components: {
        GameMap,
    }
}
</script>

<style scoped>
    div.playground {
        width: 60vw;
        height: 70vh;
        margin: 40px auto;
    }
    div.user-color {
        margin-top: 0;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
    }
    div.A {
        color: #4876ec;
    }

    div.B {
        color: #f94848;
    }
</style>