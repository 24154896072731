<template>
    <div class="container">
        <div class="row">
            <div class="col-3">
                <div class="card" style="margin-top: 20px">
                    <div class="card-body">
                        <img :src="$store.state.user.photo" alt="" style="width: 100%" />
                    </div>
                </div>
            </div>

            <div class="col-9">
                <div class="card" style="margin-top: 20px">
                    <div class="card-header">
                        <span style="font-size: 130%">我的Bot</span>
                        <button type="button" class="btn btn-primary float-end" data-bs-toggle="modal" data-bs-target="#add_bot">创建Bot</button>

                        <div class="modal fade" id="add_bot" tabindex="-1">
                            <div class="modal-dialog modal-xl">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="exampleModalLabel">创建Bot</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    
                                    <div class="modal-body">
                                        <form>
                                            <div class="mb-3">
                                                <label for="add-bot-title" class="form-label">标题</label>
                                                <input v-model="botadd.title" type="text" class="form-control" id="add-bot-title" placeholder="请输入Bot名称">
                                            </div>
                                            <div class="mb-3">
                                                <label for="add-bot-description" class="form-label">简介</label>
                                                <textarea v-model="botadd.description" class="form-control" id="add-bot-description" rows="3" placeholder="请输入Bot简介"></textarea>
                                            </div>
                                            <div class="mb-3">
                                                <label for="add-bot-content" class="form-label">内容</label>
                                                <VAceEditor v-model:value="botadd.content" @init="editorInit" lang="c_cpp" theme="textmate" style="height: 300px" />
                                            </div>
                                        </form>
                                    </div>
                                    
                                    <div class="modal-footer">
                                        <div class="error-message" style="margin-left;: 10px">{{ botadd.error_message }}</div>
                                        <button type="button" class="btn btn-primary" style="margin-right: 10px" @click="add_bot">提交</button>
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body">
                        <table class="table table-striped table-hover">
                            <thead>
                                <tr>
                                    <th>名称</th>
                                    <th>创建时间</th>
                                    <th>操作</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="bot in bots" :key="bot.id">
                                    <td style="padding-top: 15px">{{ bot.title }}</td>
                                    <td style="padding-top: 15px">{{ bot.createtime }}</td>
                                    <td>
                                        <button type="button" class="btn btn-secondary" data-bs-toggle="modal" :data-bs-target="'#update-bot-' + bot.id" style="margin-right: 10px">修改</button>

                                        <div class="modal fade" :id="'update-bot-' + bot.id" tabindex="-1">
                                            <div class="modal-dialog modal-xl">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="exampleModalLabel">修改Bot</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    
                                                    <div class="modal-body">
                                                        <form>
                                                            <div class="mb-3">
                                                                <label for="add-bot-title" class="form-label">标题</label>
                                                                <input v-model="bot.title" type="text" class="form-control" id="add-bot-title" >
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="add-bot-description" class="form-label">简介</label>
                                                                <textarea v-model="bot.description" class="form-control" id="add-bot-description" rows="3"></textarea>
                                                            </div>
                                                            <div class="mb-3">
                                                                <label for="add-bot-content" class="form-label">内容</label>
                                                                <VAceEditor v-model:value="bot.content" @init="editorInit" lang="c_cpp" theme="textmate" style="height: 300px" />
                                                            </div>
                                                        </form>
                                                    </div>
                                                    
                                                    <div class="modal-footer">
                                                        <div class="error-message" style="margin-left;: 10px">{{ bot.error_message }}</div>
                                                        <button type="button" class="btn btn-primary" style="margin-right: 10px" @click="update_bot(bot)">提交</button>
                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="refreash_bots">取消</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <button type="button" class="btn btn-danger" @click="remove_bot(bot)">删除</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { ref, reactive } from 'vue'
import $ from 'jquery'
import { Modal } from 'bootstrap/dist/js/bootstrap'
import { VAceEditor } from 'vue3-ace-editor';
import ace from 'ace-builds';

export default {
    components: {
        VAceEditor
    },

    setup() {
        const store = useStore();
        const kob_jwt_token = localStorage.getItem("kob_jwt_token");
        let bots = ref([]);

        ace.config.set(
            "basePath", 
            "https://cdn.jsdelivr.net/npm/ace-builds@" + require('ace-builds').version + "/src-noconflict/")

        const botadd = reactive ({
            title: "",
            description: "",
            content: "",
            error_message: "",
        });

        const refreash_bots = () => {
            $.ajax ({
                url: "https://kob.duangboss.top/api/user/bot/getlist/",
                type: "get",
                headers: {
                    Authorization: "Bearer " + store.state.user.token
                },
                success(resp) {
                    bots.value = resp;
                },
                error(resp) {
                    console.log(resp);
                }
            });
        };

        refreash_bots();

        const add_bot = () => {
            botadd.error_message = "";

            $.ajax ({
                url: "https://kob.duangboss.top/api/user/bot/add/",
                type: "post",
                data: {
                    title: botadd.title,
                    description: botadd.description,
                    content: botadd.content
                },
                headers: {
                    Authorization: "Bearer " + kob_jwt_token
                },
                success(resp) {
                    if(resp.error_message === "success"){
                        botadd.title = "";
                        botadd.description = "";
                        botadd.content = "";
                        Modal.getInstance("#add_bot").hide();

                        refreash_bots();
                    }
                    else {
                        botadd.error_message = resp.error_message;
                    }
                },
                error(resp) {
                    botadd.error_message = resp.error_message;
                }
            });
        };

        const remove_bot = (bot) => {
            $.ajax ({
                url: "https://kob.duangboss.top/api/user/bot/remove/",
                type: "post",
                data: {
                    bot_id: bot.id,
                },
                headers: {
                    Authorization: "Bearer " + kob_jwt_token
                },
                success(resp) {
                    alert(resp.error_message);
                    refreash_bots();
                },
                error(resp) {
                    alert(resp.error_message);
                    refreash_bots();
                }
            });
        };

        const update_bot = (bot) => {
            $.ajax ({
                url: "https://kob.duangboss.top/api/user/bot/update/",
                type: "post",
                data: {
                    bot_id: bot.id,
                    title: bot.title,
                    description: bot.description,
                    content: bot.content
                },
                headers: {
                    Authorization: "Bearer " + kob_jwt_token
                },
                success(resp) {
                    if(resp.error_message === "success"){
                        Modal.getInstance('#update-bot-' + bot.id).hide();

                        refreash_bots();
                    }
                    else {
                        bot.error_message = resp.error_message;
                    }
                },
                error(resp) {
                    bot.error_message = resp.error_message;
                }
            });
        };

        return {
            refreash_bots,
            add_bot,
            botadd,
            remove_bot,
            update_bot,
            bots
        }
    }
}
</script>

<style scoped>
    .error-message {
        color: red;
    }
</style>