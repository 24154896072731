import { createStore } from 'vuex'
import ModulesUser from './user'
import ModulePk from './pk'
import ModuleRecord from './record'

export default createStore({
	state: {
	},
	getters: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		user: ModulesUser,
		pk: ModulePk,
		record: ModuleRecord,
	}
})
